/**
 * Determines if the provided value is a Boolean, defined and true.
 *
 * @param {any} booleanValue The value to test.
 * @returns{Boolean} A boolean indicating if the supplied value was a Boolean and if it was then if its value is true.
 */
export const is = (booleanValue) => {
  if (typeof booleanValue === "boolean") {
    return booleanValue && !!booleanValue;
  }

  return false;
};

/**
 * Determines if the provided value is a Boolean, defined and false.
 *
 * @param {any} booleanValue The value to test.
 * @returns{Boolean} A boolean indicating if the supplied value was a Boolean and if it was then if its value is false.
 */
export const isNot = (booleanValue) => {
  return !is(booleanValue);
};
