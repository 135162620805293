export default {
  featuredDealsConfigurations: [
    {
      containerId: "1",
      configurations: [
        {
          isTopDeal: true,
          supplier: "Network Solutions",
          productCategory: "Web Hosting Services",
          rating: 5,
          overviewText:
            "Currently offering 50% off a second domain when brought with a full price one. So easy to use your grandma could do it. 30 day money back guarantee, live phone support and direct Wordpress integration. Get that idea on the web today. Read our comparison of %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/business/best-web-hosting-services/",
          serviceDetails: "Use Code IRDOM002AF ",
          targetUrl: "https://network-solutions.7eer.net/c/1323808/972495/555",
          targetUrlTitle: "",
          price: "$4.98",
          imageKey: "networkSolutions",
          imageAltText: "Network Solutions - Where It All Begins With A Domain",
        },
        {
          isTopDeal: true,
          supplier: "TransUnion",
          productCategory: "Credit Services",
          rating: 4,
          overviewText:
            "Of the 3 big credit services, TransUnion are by far the most accommodating and human. We recommend them for credit reporting but more importantly, credit REPAIR. They're not perfect, but they are far better than the others.",
          overviewTextComparisonUrl: "",
          serviceDetails: "Monthly Price",
          targetUrl: "https://transunion.ifgza3.net/veNqv",
          targetUrlTitle: "",
          price: "$24.95",
          imageKey: "transUnion",
          imageAltText: "Be in the know with TransUnion – see your credit score Now",
        },
        {
          isTopDeal: true,
          supplier: "Pulse Power",
          productCategory: "Electric Rates",
          rating: 4,
          overviewText:
            "12 month minimum term, with 24 and 36 available. 19% renewable energy and easy sign up process. Available in Texas only, but we've yet to find an energy supplier that is as easy to deal with. Business & Residential. Read our comparison of %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/utilities/electricity-prices-by-state/",
          serviceDetails: "Per KW/h",
          targetUrl: "https://www.pulsepowertexas.com/enrollment?promoCode=compareco&zipcode=XXXXX",
          targetUrlTitle: "",
          price: "4.1c",
          imageKey: "pulsePower",
          imageAltText: "Pulse Power Understand the Pulse of Retail Electricity",
        },
        {
          isTopDeal: true,
          supplier: "Clearcover",
          productCategory: "Car Insurance",
          rating: 5,
          overviewText:
            "Great price, easy recurring monthly payment & user friendly website. Did we mention the higher coverage amounts for less $$$ than the other guys? Read our comparison of  %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/insurance/car-insurance-quotes/",
          serviceDetails: "6 Months/25 year old",
          targetUrl: "https://clearcover.com/comparison",
          targetUrlTitle: "",
          price: "$831",
          imageKey: "clearCover",
          imageAltText: "Clearcover - Spend less for better coverage",
        },
      ],
    },
    {
      containerId: "2",
      configurations: [
        {
          isTopDeal: true,
          supplier: "SetMeUp Group",
          productCategory: "Renters Insurance",
          rating: 5,
          overviewText:
            // "Available in Texas only, but one of the most progressive and easy to use rental insurance services we've ever found. Customer service is phenomenal; the rates are great and you can find whatever coverage you want no matter what your circumstances. The difference we found with SetMeUp is that you can get complete coverage for a fraction of the cost of other insurance providers. Simple & affordable rental insurance you can pay in pretty much any way you like. Tick it off your list right now. Find out more with our comparison of %/PRODUCT_CATEGORY/%",
            "Available in Texas only, the most progressive and easy to use rental insurance services we've found. Customer service is phenomenal; the rates are great and you can find whatever coverage you want no matter what your circumstances. Simple & affordable rental insurance which you can pay in any way you like. Find out more with our comparison of %/PRODUCT_CATEGORY/%",

          overviewTextComparisonUrl: "/insurance/renters-insurance/",
          serviceDetails: "Monthly Price",
          targetUrl: "https://bit.ly/TCCSetMeUpWEB",
          targetUrlTitle: "",
          price: "$9.33",
          imageKey: "setMeUpGroup",
          imageAltText: "SetMeUpGroup - Sign Your Lease, Choose SetMeUp",
        },
        {
          isFeaturedPickDeal: true,
          supplier: "Mint Mobile",
          productCategory: "Cell Plans",
          rating: 5,
          overviewText:
            "Mint Mobile offers users the ability to bulk buy their cell contracts in advanced to provide the best prices. New users can sign up for a 3 month trial at the best price offered for the selected plan. You can then renew for 3, 6 or 12 months and the longer you buy, the cheaper it is. Learn more about Mint Mobile on our comparison of %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/cell/the-best-phones/",
          serviceDetails: "Monthly In Advance",
          targetUrl: "https://mint-mobile.58dp.net/c/1323808/982692/7915",
          targetUrlTitle: "",
          price: "$15",
          imageKey: "mintMobile",
          imageAltText: "Mint Mobile - It's Not Magic It's Brilliant",
        },
        {
          isBlogArticles: true,
        },
      ],
    },
    {
      containerId: "3",
      configurations: [
        {
          isTestimonial: true,
          text:
            "The Comparison Company made it very easy to compare and sign up for everything I needed to get my new home-based business off the ground. From domain and website creation to applying for a credit card and bookkeeping for my new business, The Comparison Company gave me the confidence that I was getting the best price and service for everything I needed.",
          name: "Mallory",
          imageKey: "mallory",
          imageAltText: "Mallory - The Comparison Company User From Addison, TX",
          location: "Addison, TX",
          rating: 4,
          color: "#BF3666",
        },
        {
          isTopDeal: true,
          supplier: "Canva",
          productCategory: "Marketing",
          rating: 5,
          overviewText:
            "Ever wondered how some people or businesses are able to post so many beautiful and interesting posts so fast? Well, now you know. Canva describe themselves as your marketing 'secret weapon' and they're right. Get ahead with Canva. Seriously good. We use the 'Pro' plan.",
          overviewTextComparisonUrl: "",
          serviceDetails: "",
          targetUrl: "https://partner.canva.com/yGmEB",
          targetUrlTitle: "",
          price: "Free",
          imageKey: "canva",
          imageAltText: "Canva - Design Anything",
        },
        {
          isTopDeal: true,
          supplier: "Weebly",
          productCategory: "Web Hosting Services",
          rating: 5,
          overviewText:
            "Build a website in minutes. It really is that simple. They make it easy to take care of everything from buying a domain to designing and sending your new site online. There are others but Weebly is ahead of the pack. ",
          overviewTextComparisonUrl: "",
          serviceDetails: "",
          targetUrl: "https://www.shareasale.com/m-pr.cfm?merchantID=37723&userID=2648097&productID=981365770",
          targetUrlTitle: "",
          price: "Free",
          imageKey: "weebly",
          imageAltText: "Weebly - Get started with our powerful free website builder.",
        },
        {
          isTestimonial: true,
          text:
            "I recently moved to Houston from out of state and didn’t know where to start or who to trust with everything I needed for my new home. Luckily I found The Comparison Company and was able to quickly and easily compare electric rates, cable and internet, and even insurance confidently all in one place.",
          name: "Travis",
          imageKey: "travis",
          imageAltText: "Travis - The Comparison Company User From Houston, TX",
          location: "Houston, TX",
          rating: 3,
          color: "#7A62A7",
        },
      ],
    },
    {
      containerId: "4",
      configurations: [
        {
          isTopDeal: true,
          supplier: "Xfinity",
          productCategory: "Cell Plans",
          rating: 4,
          overviewText:
            "5G, good coverage, low prices and all the devices you could want. 'Bring your own phone' is limited to iPhone only. Best for those who just want the essentials with a service that works. No frills, no drama. Read our comparison of %/PRODUCT_CATEGORY/%",
          // "5G, good coverage, low prices and all the devices you could want. 'Bring your own phone' is limited to iPhone only. Best for those who just want the essentials with a service that works. No frills, no drama. Comparison HERE",
          overviewTextComparisonUrl: "/cell/the-best-phones/",
          serviceDetails: "Per line, per month",
          targetUrl: "https://xfinity.ulvh.net/c/1323808/823179/9001",
          targetUrlTitle: "",
          price: "$15",
          imageKey: "xfinity",
          imageAltText: "Xfinity - Your wireless. Your rules. Only with Xfinity Mobile.",
        },
        {
          isTopDeal: true,
          supplier: "Bestow",
          productCategory: "Life Insurance",
          rating: 4,
          overviewText:
            "Only 10 and 20 year policies available at the moment so we gave this otherwise excellent service 4 stars. 21-54 year olds are eligible with no medical exam. Very easy process. Review the best %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/insurance/compare-life-insurance-products/",
          serviceDetails: "Monthly",
          targetUrl: "https://bestow.sjv.io/P1yge",
          targetUrlTitle: "",
          price: "$18.17",
          imageKey: "bestow",
          imageAltText: "Bestow - Easy, affordable, term life insurance",
        },

        {
          isTopDeal: true,
          supplier: "Xoom",
          productCategory: "Electricity Rates",
          rating: 3,
          overviewText:
            "Good prices, excellent customer service and flexible term length is let down by very poor online portal. If you can get past this frustration, make use of the ability to lock in rates for up to 3 years. Compare %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/cell/the-best-phones/",
          serviceDetails: "Monthly Plan",
          targetUrl: "https://xoomenergy.sjv.io/MA53o",
          targetUrlTitle: "",
          price: "$65.49",
          imageKey: "xoomEnergy",
          imageAltText: "Xoom - Service you can depend on with choices to fit your lifestyle.",
        },
        {
          isTopDeal: true,
          supplier: "Credit Karma",
          productCategory: "Credit Services",
          rating: 4,
          overviewText:
            "Checking your credit should be free. With Credit Karma it is and we like them. BUT, the set up isn't nearly as easy as you want it to be; password creation is a pain and linking accounts takes far too long. It's the best in the market, but they could be EVEN better than they are.",
          overviewTextComparisonUrl: "",
          serviceDetails: "",
          targetUrl: "https://creditkarma.myi4.net/a3nZM",
          targetUrlTitle: "",
          price: "Free",
          imageKey: "creditKarma",
          imageAltText: "Credit Karma - Your credit scores should be free. And now they are.",
        },
      ],
    },
    {
      containerId: "5",
      configurations: [
        {
          isTopDeal: true,
          supplier: "Credit Assistance Network",
          productCategory: "Credit Services",
          rating: 5,
          overviewText:
            "None of us are perfect and 2020 has made it even harder to keep your credit as good as you might want. Rebuilding or simply increasing your credit is possible with Credit Assistance Network. You can save thousands by increasing your score even slightly so we think that investing in credit repair is well worth it. These guys are great. Try today.",
          overviewTextComparisonUrl: "",
          serviceDetails: "",
          targetUrl: "https://shareasale.com/r.cfm?b=194535&u=2648097&m=24278&urllink=&afftrack=",
          targetUrlTitle: "",
          price: "Free",
          imageKey: "creditAssistanceNetwork",
          imageAltText: "Credit Assistance Network - Credit Improvement OPEN THE DOOR TO NEW POSSIBILITIES!",
        },
        {
          isTopDeal: true,
          supplier: "Kin Insurance",
          productCategory: "Home Insurance",
          rating: 4,
          overviewText:
            "Niche insurance for disaster-prone areas of the US. Their customer service was great, the coverage terms very reasonable and they seem to really want to make things more simple. It's odd for us to include a service that is so focused on certain Customers, but they deserve the recognition.",
          overviewTextComparisonUrl: "",
          serviceDetails: "",
          targetUrl: "https://imp.i149502.net/oQybe",
          targetUrlTitle: "",
          price: "Custom",
          imageKey: "kinInsurance",
          imageAltText: "Kin Insurance - The Home for Better Insurance.",
        },
        {
          isTopDeal: true,
          supplier: "Tally",
          productCategory: "Personal Debt Management",
          rating: 5,
          overviewText:
            "Tally lets you cut the APR of your existing credit card debt and lower your monthly payments. They give you a line of credit (once you qualify) that covers your debts and then THEY make the payments for you. No more late fees. Get those cards under control now with a free trial when you sign up for %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/financial/debt-solutions/",
          serviceDetails: "",
          targetUrl: "https://tally.sjv.io/rV5aB",
          price: "Free",
          imageKey: "tally",
          imageAltText: "Tally - Pay Off Debt Faster.",
        },
        {
          isTestimonial: true,
          text:
            "With everything in the news today about student loan debt, I felt overwhelmed and didn’t know where to start. With The Comparison Company, I was able to look at all the top options available for refinancing my student loans and start saving money on my monthly expenses. All of the different features of each option were clearly laid out for me to know that I was getting the best deal for myself.",
          name: "Evelyn",
          imageKey: "evelyn",
          imageAltText: "Evelyn - The Comparison Company User From Dallas, TX",
          location: "Dallas, TX",
          rating: 5,
          color: "#016a9f",
        },
      ],
    },
    {
      containerId: "6",
      configurations: [
        {
          isTopDeal: true,
          supplier: "LastPass",
          productCategory: "Personal Password Managers",
          rating: 1,
          overviewText:
            "Customer service is not responsive, the online interface is clunky and it conflicts with iOS Keychain. BUT, we're including LastPass because they offer good cross-platform integration and their security is better than what we found elsewhere. Push through, it can be worth it.",
          overviewTextComparisonUrl: "",
          serviceDetails: "",
          targetUrl: "https://lastpass.wo8g.net/jKr40",
          targetUrlTitle: "",
          price: "Free",
          imageKey: "lastPass",
          imageAltText: "LastPass- Effortless Security From Anywhere.",
        },
        {
          isTopDeal: true,
          supplier: "Coinbase",
          productCategory: "Personal Crypto Services",
          rating: 4,
          overviewText:
            "Crypto trading has changed a lot in the past few years, and so has Coinbase while remaining the public crypto-trading leader in ease of use and range of derivatives. Their fees aren't the lowest, but the interface is good and the integrations with banks and payment methods make them our pick. ",
          overviewTextComparisonUrl: "",
          serviceDetails: "₿5 For Sign Up",
          targetUrl: "https://coinbase-consumer.sjv.io/KkjG9",
          targetUrlTitle: "",
          price: "Free",
          imageKey: "coinbase",
          imageAltText: "Coinbase - Buy and sell cryptocurrency.",
        },
        {
          isTopDeal: true,
          supplier: "Handy.com",
          productCategory: "Home Cleaning Services",
          rating: 4,
          overviewText:
            "Get on top of your household chores with a 50% discount on your first clean with TCC. Almost any task you can think of can be taken care of and each member of Handy is background checked. ",
          overviewTextComparisonUrl: "",
          serviceDetails: "Use Code FIRST50OFF",
          targetUrl: "https://handy.7eer.net/c/1323808/648322/3369",
          targetUrlTitle: "",
          price: "Custom",
          imageKey: "handyCom",
          imageAltText: "Handy.com - Book top-rated professionals for all your home needs.",
        },
        {
          isTopDeal: true,
          supplier: "Blinkist",
          productCategory: "Consumer Goods",
          rating: 5,
          overviewText: "The 'Cliff notes of the Cliff notes'. An incredibly easy way to get the highlights of thousands of books, via audio, whenever and wherever. ",
          overviewTextComparisonUrl: "",
          serviceDetails: "",
          targetUrl: "https://blinkist.o6eiov.net/2nWXM",
          targetUrlTitle: "",
          price: "Free",
          imageKey: "blinkist",
          imageAltText: "Blinkist - Fit learning into your life.",
        },
      ],
    },
    {
      containerId: "7",
      configurations: [
        {
          isInfoBox: true,
          title: "77% Americans think this is the most important insurance!",
          overviewText:
            "A new home is probably the biggest purchase you will make and protecting that investment should be your priority. That comes down to purchasing homeowners insurance coverage and understanding your homeowners insurance policy. Not only does homeowners insurance protect your home and your belongings from theft, fire, accidents and even the weather, but it’s also a necessity to receive a mortgage.",
          targetUrl: "/insurance/homeowners/",
          targetUrlTitle: "",
          buttonText: "Compare Home Insurance",
          imageKey: "lifeInsuranceInfo",
          imageAltText: "The Comparison Company - Compare Home Insurance.",
        },
        {
          isTopDeal: true,
          supplier: "Deep Sentinel",
          productCategory: "Home Security Products",
          rating: 4,
          overviewText:
            "Real time, in-person monitoring means that there's actually someone to talk to in the case of an issue. They don't integrate with Alexa, Nest or smart locks so we only gave them 4 stars. Otherwise an excellent product. Review our comparison of %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/home/best-home-security-systems/",
          serviceDetails: "Monthly Price",
          targetUrl: "https://deep-sentinel-home-security.pxf.io/Ar4kj",
          targetUrlTitle: "Monthly Price",
          price: "$100",
          imageKey: "deepSentinel",
          imageAltText: "Deep Sentinel - Real human guards watching your cameras in real-time.",
        },
        {
          isTopDeal: true,
          supplier: "Scout Alarm",
          productCategory: "Home Security Products",
          rating: 5,
          overviewText: "Easy to integrate with other systems like Alexa and Google Home. No contract. Read why Scout come top of our comparison of the top 5 %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/home/best-home-security-systems/",
          serviceDetails: "Monthly Price",
          targetUrl: "https://scout-alarm.qbt4.net/QV2r6",
          targetUrlTitle: "",
          price: "$19.99",
          imageKey: "scoutAlarm",
          imageAltText: "Scout Alarm - Home Security. Simplified.",
        },
      ],
    },
    {
      containerId: "8",
      configurations: [
        {
          isTopDeal: true,
          supplier: "H & R Block",
          productCategory: "Personal Tax Services",
          rating: 5,
          overviewText:
            "$0 for simple returns and $44.99 for itemized returns. Excellent video tutorials and full range of forms for investors, 1099s, Schedule C and rental incomes, although these cost extra. It's really easy to use, covers more than most free software and the extras are well worth it. Read our full comparison of %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/financial/tax-and-accounting-services/",
          serviceDetails: "",
          targetUrl: "https://linkto.hrblock.com/2n107",
          targetUrlTitle: "",
          price: "Free",
          imageKey: "hrBlock",
          imageAltText: "H & R Block - Say hello to better tax prep.",
        },
        {
          isTopDeal: true,
          supplier: "FreshBooks",
          productCategory: "Financial",
          rating: 4,
          overviewText: "Get a free trial through TCC and get your personal or business finances in order. Perfect way to escape 2020 and reach the financial goals you have in 2021.",
          overviewTextComparisonUrl: "",
          serviceDetails: "",
          targetUrl: "https://shareasale.com/r.cfm?b=610226&u=2648097&m=52946&urllink=&afftrack=",
          targetUrlTitle: "",
          price: "Free",
          imageKey: "freshBooks",
          imageAltText: "FreshBooks - Invoice Software That Saves You Time.",
        },
        {
          isInfoBoxAd: true,
          targetUrl: "/insurance/car-insurance-quotes/",
          buttonText: "Find Out More",
          imageKey: "carInfo",
          imageAltText: "The Comparison Company - Compare Car Insurance.",
          buttonColor: "yellow",
          topPositionPercentage: "82%",
          leftPositionPercentage: "25%",
        },
      ],
    },
    {
      containerId: "9",
      configurations: [
        {
          isTopDeal: true,
          supplier: "Loan Depot",
          productCategory: "Home Loans",
          rating: 5,
          overviewText:
            "Long term home loans of up to 30 years can mean you're able to upgrade now and spread the cost easily. No lender fees, reimbursement of appraisal fees add to their life-time guarantee on future refinancing make them our pick for your new home. Compare %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/loans/refinance-mortgage-rates/",
          serviceDetails: "3 - 10 Years",
          targetUrl: "https://loandepot.sjv.io/J5zyq",
          targetUrlTitle: "",
          price: "Custom",
          imageKey: "loanDepot",
          imageAltText: "Loan Depot - Simple. Secure. Smart.",
        },
        {
          isTopDeal: true,
          supplier: "Upgrade",
          productCategory: "Personal Loans",
          rating: 5,
          overviewText:
            "Fixed rate personal loans of up to $50,000 without the hassle. Credit cards, debt repair, home improvement or building a COVID bunker. They can help take control of mounting COVID debt. It will all be fine.",
          overviewTextComparisonUrl: "",
          serviceDetails: "",
          targetUrl: "https://upgrade.ywhcc7.net/73JxV",
          targetUrlTitle: "",
          price: "To $50K",
          imageKey: "upgrade",
          imageAltText: "Upgrade - Personal Loans up to $50,000.",
        },
        {
          isTopDeal: true,
          supplier: "Splash",
          productCategory: "Student Loan Refinancing",
          rating: 5,
          overviewText:
            "Ideal for graduates and medical students. Long loan term of 10 up to 20 years eases the burden at a crucial time. Read how we compare different providers of %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/loans/refinancing-student-loans/",
          serviceDetails: "",
          targetUrl: "https://splash-financial.j48ltb.net/P1m9R",
          targetUrlTitle: "",
          price: "1.89% APR",
          imageKey: "splash",
          imageAltText: "Splash - Your go-to for savings on student loans.",
        },
        {
          isTopDeal: true,
          supplier: "Boro",
          productCategory: "Car Loans",
          rating: 3,
          overviewText:
            "We included Boro, not because it has got the best rates, but because of it's unique focus on international students. APR is higher than it's possible to get, but borrowers are spared the spread of rates from other providers. Good online portal, easy terms and good customer service. Compare %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/loans/auto-loan-rates/",
          serviceDetails: "",
          targetUrl: "https://boro.nrlq2m.net/ALWMo",
          targetUrlTitle: "",
          price: "6% APR",
          imageKey: "boroCar",
          imageAltText: "Boro - The best car loan for college students.",
        },
      ],
    },
    {
      containerId: "10",
      configurations: [
        {
          isInfoBoxAd: true,
          targetUrl: "/app/login",
          imageKey: "saveMoneyInfo",
          buttonText: "Start Saving",
          buttonColor: "green",
          topPositionPercentage: "82%",
          leftPositionPercentage: "25%",
          imageAltText: "The Comparison Company - Sign Up And Save Money",
        },
        {
          isTopDeal: true,
          supplier: "Haven Life",
          productCategory: "Life Insurance",
          rating: 4,
          overviewText:
            "30 year term makes it amongst the longest policies, combined with a low fee. Application is 100% online and decisions are immediate. For that alone we gave them 4 stars. It would be 5 but for the fact that 'Term' policies can NOT be converted to 'whole life'. Compare %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/insurance/compare-life-insurance-products/",
          serviceDetails: "Per Month",
          targetUrl: "https://havenlife.sjv.io/5Og02",
          targetUrlTitle: "",
          price: "$16.25",
          imageKey: "havenLife",
          imageAltText: "Haven Life - Life insurance that’s actually simple.",
        },
        {
          isTopDeal: true,
          supplier: "HealthIQ",
          productCategory: "Health Insurance",
          rating: 5,
          overviewText:
            "Custom pricing means the quote process takes a little longer, but we think it's worth the wait. We've seen savings between 8% - 41% if you can show your fitness level. Best for the most active among us. Compare our top 5 %/PRODUCT_CATEGORY/%",
          overviewTextComparisonUrl: "/insurance/compare-life-insurance-products/",
          serviceDetails: "",
          targetUrl: "https://www.healthiq.com/life-insurance/comparison-company?utm_source=comparisoncompany",
          targetUrlTitle: "",
          price: "Custom",
          imageKey: "healthIQ",
          imageAltText: "HealthIQ - Get a quote in minutes & save.",
        },
      ],
    },
    {
      containerId: "11",
      configurations: [
        {
          isTopDeal: true,
          supplier: "Constant Contact",
          productCategory: "Business Email Services",
          rating: 5,
          overviewText:
            "Experience matters. Their mailservers are so established your marketing emails are far more likely to get to the right place than anyone else. Other services may make the emails look more beautiful, but that doesn't matter if they never arrive. ",
          overviewTextComparisonUrl: "",
          serviceDetails: "",
          targetUrl: "https://constant-contact.ibfwsl.net/Grm4L",
          targetUrlTitle: "",
          price: "Free",
          imageKey: "constantContact",
          imageAltText: "Constant Contact - Email marketing and more, made easy.",
        },
        {
          isTopDeal: true,
          supplier: "SEMRush",
          productCategory: "Marketing",
          rating: 4,
          overviewText:
            "The world of SEO is full of charlatans. They make the huge amount of information accessible. Competitor analysis, keyword cost and effective backlink management is handled without being overwhelming. We use them. ",
          overviewTextComparisonUrl: "",
          serviceDetails: "Monthly Price",
          targetUrl: "https://shareasale.com/r.cfm?b=1545494&u=2648097&m=97231&urllink=&afftrack=0",
          targetUrlTitle: "",
          price: "$119.95",
          imageKey: "semRush",
          imageAltText: "SEMRush - Online Visibility Management Platform.",
        },
        {
          isTopDeal: true,
          supplier: "Domain.com",
          productCategory: "Web Domain Management",
          rating: 5,
          overviewText: "Launching a new website is complicated in lots of ways. They make it simple. Give them a try. We used them.",
          overviewTextComparisonUrl: "",
          serviceDetails: "",
          targetUrl: "https://domain.mno8.net/Bq0Vq",
          targetUrlTitle: "",
          price: "$85",
          imageKey: "domainCom",
          imageAltText: "Domain.com - Do more, online.",
        },
        {
          isTopDeal: true,
          supplier: "Rocket.inc",
          productCategory: "Web Hosting Services",
          rating: 5,
          overviewText:
            "Everything from domain search to publishing any number of sites. We tried to find something more exciting to say but the service is so simple it speaks for itself. Give it a try.",
          overviewTextComparisonUrl: "",
          serviceDetails: "",
          targetUrl: "https://www.shareasale.com/m-pr.cfm?merchantID=100273&userID=2648097&productID=1185146535",
          targetUrlTitle: "",
          price: "$25",
          imageKey: "rocketInc",
          imageAltText: "Rocket.inc - Build Your Site on the Fastest WordPress Platform",
        },
      ],
    },
    {
      containerId: "12",
      configurations: [
        {
          isTopDeal: true,
          supplier: "Bank Novo",
          productCategory: "Business Banking",
          rating: 3,
          overviewText:
            "Innovative online only banking with unlimited transactions for $0 annual fee. Customer service is excellent and the application process is very easy but not always consistent. ",
          overviewTextComparisonUrl: "",
          serviceDetails: "$25 Enrolment",
          targetUrl: "https://banknovo.qkweww.net/e7VKQ",
          targetUrlTitle: "",
          price: "Free",
          imageKey: "bankNovo",
          imageAltText: "Bank Novo - Powerfully Simple Business Banking.",
        },
        {
          isTopDeal: true,
          supplier: "CoverWallet",
          productCategory: "Financial Insurance",
          rating: 5,
          overviewText: "Simple, efficient, effective. Insurance shouldn't be exciting. They have a great reputation so we were keen to 'kick the tires'. The tires stayed on.",
          overviewTextComparisonUrl: "",
          serviceDetails: "Monthly Price",
          targetUrl: "https://www.kqzyfj.com/click-8932735-13478523",
          targetUrlTitle: "",
          price: "$30",
          imageKey: "coverWallet",
          imageAltText: "Coverwallet - It's easier than ever to get the best insurance for your business.",
        },

        {
          isTopDeal: true,
          supplier: "CorpNet",
          productCategory: "Business Management",
          rating: 5,
          overviewText:
            "There's a lot to consider when starting a business and CorpNet really do make it easy. Take your time, spend less money and focus on what you're most passionate about. A name you might not know, but you're going to want to. ",
          overviewTextComparisonUrl: "",
          serviceDetails: "Incorporation",
          targetUrl: "https://shareasale.com/r.cfm?b=233712&u=2648097&m=27261&urllink=&afftrack=",
          targetUrlTitle: "",
          price: "$79",
          imageKey: "corpNet",
          imageAltText: "CorpNet - Business Filings Made Simple.",
        },
        {
          isTopDeal: true,
          supplier: "Dun & Bradstreet",
          productCategory: "Business Credit Services",
          rating: 1,
          overviewText:
            "Our reviewers signed up for 3 DUNS numbers with no problem, but the 4th took 5 weeks before we gave up. Customer service is automated, extremely restrictive and often unhelpful. 75% of the time it works every time. Sadly, having a DUNS number is extremely useful so despite this, it's worth persevering. ",
          overviewTextComparisonUrl: "",
          serviceDetails: "",
          targetUrl: "https://dandb.7eer.net/5OgDN",
          targetUrlTitle: "",
          price: "Free",
          imageKey: "dunBradstreet",
          imageAltText: "Dun & Bradstreet - Receivables Intelligence.",
        },
      ],
    },
  ],
};
