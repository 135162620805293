import React from "react";
import { Link } from "gatsby";
import { convertToLowerCase } from "../../../../utils/StringUtils";

/**
 * Provides a way to request a default value for supplied component props fields.
 *
 * @param {String} propName The name of the supplied component prop that is being queried for a default value.
 *
 */
const getDefaultValueFor = (propName) => {
  switch (convertToLowerCase(propName)) {
    case "buttontext":
      return "Find Out More";
    case "buttoncolor":
      return "yellow";
    case "toppositionpercentage":
      return "82%";
    case "leftpositionpercentage":
      return "25%";
    default:
      return "";
  }
};

/**
 * Returns a Gatsby Link element configured & styled using the provided data, based on the specified buttonColor field in the associated card config.
 *
 * Note - The button elements exported here are defined as the "purge" option is set to true in the Tailwind config. This results in a much smaller production file but strips away the
 * unused styles and that includes any dynamically generated using string templates. This utility file is defined as a way around this issue as I want to be able to define the color
 * button to be used in the data config dynamically.
 *
 * @param {*} props
 */
export const generateLinkButtonUsing = (props) => {
  let { buttonText, targetUrl, buttonColor, topPositionPercentage, leftPositionPercentage, imageAltText } = props.config;

  buttonText = buttonText || getDefaultValueFor("buttonText");
  buttonColor = buttonColor || getDefaultValueFor("buttonColor");
  topPositionPercentage = topPositionPercentage || getDefaultValueFor("topPositionPercentage ");
  leftPositionPercentage = leftPositionPercentage || getDefaultValueFor("leftPositionPercentage");

  const coreButtonStyle = "w-1/2 absolute px-4 text-white hover:text-white py-1 text-sm text-center rounded-md lg:mt-4 md:text-lg md:py-3 lg:py-2 lg:text-lg";

  switch (buttonColor) {
    case "yellow":
      return (
        <Link
          to={targetUrl}
          className={`${coreButtonStyle} text-gray-landingPage800 hover:text-gray-landingPage800 border border-yellow-landingPage900 hover:border-yellow-landingPage900 bg-yellow-landingPage800 hover:bg-yellow-landingPage900 `}
          style={{ top: topPositionPercentage, left: leftPositionPercentage }}
        >
          {buttonText}
        </Link>
      );
    case "blue":
      return (
        <Link
          to={targetUrl}
          className={`${coreButtonStyle} text-white hover:text-white border border-blue-landingPage700 hover:border-blue-landingPage800 bg-blue-landingPage700 hover:bg-blue-landingPage800 `}
          style={{ top: topPositionPercentage, left: leftPositionPercentage }}
        >
          {buttonText}
        </Link>
      );
    case "green":
      return (
        <Link
          to={targetUrl}
          className={`${coreButtonStyle} text-white hover:text-white border border-green-landingPage700 hover:border-green-landingPage800 bg-green-landingPage700 hover:bg-green-landingPage800 `}
          style={{ top: topPositionPercentage, left: leftPositionPercentage }}
        >
          {buttonText}
        </Link>
      );
    default:
      throw new Error(`Unknown InfoAdBox Button Color ${buttonColor} For ${imageAltText}`);
  }
};
