import React, { useContext } from "react";
import TCCOutboundLink from "../../../TCCOutboundLink";
import PropTypes from "prop-types";
import PositiveStarSVG from "../../../../../svg/LandingPage/DealCard/starcolor.svg";
import EmptyStarSVG from "../../../../../svg/LandingPage/DealCard/stargrey.svg";
import { v4 as uuidv4 } from "uuid";
import { createRatingArrayFrom } from "../../../../services/RatingService";
import Img from "gatsby-image";
import LandingPageImageContext from "../../../../contexts/landingPageImageContext";
import IpGeoDataContext from "../../../../contexts/ipGeoDataContext";
import { generateLinkUrlWithParams } from "../../../../services/LandingPageUrlService";
import { generateLandingPageSupplierLinkBioUsing } from "../../../../services/LandingPageComparisonLinkService";

/**
 * Returns either the provided rating or 1 (Default) if the rating is not a valid number.
 *
 * @param {Number} rating The number of stars to render for the card.
 */
const getWithDefault = (rating) => {
  const DEFAULT_RATING = 1;

  if (isNaN(rating)) {
    return DEFAULT_RATING;
  }

  return rating || DEFAULT_RATING;
};

/**
 * Returns an element that renders the Pick Of The Week Featured card based on the supplied data.
 *
 * @param {Object} props An object containing the data required to be rendered by the generated element.
 */
const FeaturedPickCard = (props) => {
  const { overviewTextComparisonUrl, supplier, productCategory, rating, imageKey, imageAltText, overviewText, serviceDetails, targetUrl, targetUrlTitle, price } = props.config;
  const landingPageImages = useContext(LandingPageImageContext);
  const ipGeoData = useContext(IpGeoDataContext);
  const { postalCode } = ipGeoData;
  const processedTargetUrl = generateLinkUrlWithParams(targetUrl, { zipCode: postalCode });
  const PICK_LABEL = "Pick Of The Month";

  return (
    <div className="h-full px-4 pt-4 pb-4 m-auto">
      <div className="flex flex-col h-full -mt-0 bg-white">
        {true && (
          <div className="relative top-0 left-0 z-50 w-full p-2 text-sm font-bold text-center border rounded-md rounded-tr-none rounded-bl-none rounded-br-none text-gray-landingPage800 md:w-1/2 lg:w-1/3 rounded-tl-md border-yellow-landingPage700 bg-yellow-landingPage700">
            <span>{PICK_LABEL}</span>
          </div>
        )}
        <div className={`${true ? `-mt-10` : `mt-0`}`}>
          <TCCOutboundLink href={targetUrl}>
            {imageKey && landingPageImages[imageKey] && <Img className="w-full rounded-md shadow-lg h-w" alt={imageAltText} fluid={landingPageImages[imageKey].childImageSharp.fluid} />}
            {!imageKey && <img className="w-full rounded-md shadow-lg h-w" alt={imageAltText} src="https://source.unsplash.com/bGWVhFY1gH0" />}
          </TCCOutboundLink>
        </div>
        <TCCOutboundLink href={targetUrl}>
          <p className="px-0 pt-2 text-sm text-gray-900 lg:text-2xl">{supplier}</p>
          <p className="p-0 mb-1 text-sm lg:text-base text-gray-landingPage800">{productCategory}</p>
        </TCCOutboundLink>
        <div className="flex flex-grow-0">
          <TCCOutboundLink href={targetUrl}>
            {createRatingArrayFrom(getWithDefault(rating)).map((r) => {
              if (r === 1) {
                return <PositiveStarSVG key={uuidv4()} className="inline-flex h-6 mr-1" />;
              } else {
                return <EmptyStarSVG key={uuidv4()} className="inline-flex h-6 mr-1" />;
              }
            })}
          </TCCOutboundLink>
        </div>
        <div className="flex flex-grow ">
          <p className="px-0 mt-4 mb-4 mr-0 text-xs text-gray-landingPage800 sm:text-sm md:text-sm lg:text-sm">
            {generateLandingPageSupplierLinkBioUsing(overviewText, overviewTextComparisonUrl, productCategory, targetUrl)}
          </p>
        </div>
        <div className="flex flex-grow"></div>
        <div className="flex flex-grow-0">
          <TCCOutboundLink href={targetUrl} className="w-full">
            {price && (
              <div className="inline-block w-full">
                <div className="inline-block w-full lg:w-1/4">
                  <p className="w-1/2 text-xs text-center border rounded-md shadow-md lg:w-10/12 text-green-landingPage800 border-green-landingPage800 sm:text-sm md:text-sm lg:text-sm ">{price}</p>
                </div>
                <div className="inline-block w-full text-left lg:w-2/4">
                  {serviceDetails && <p className="px-0 mt-1 text-xs rounded-md text-gray-landingPage600 sm:text-sm md:text-sm lg:text-sm">{serviceDetails}</p>}
                  {!serviceDetails && <p className="px-0 mt-1 text-xs rounded-md text-gray-landingPage600 sm:text-sm md:text-sm lg:text-sm">&nbsp;</p>}
                </div>
              </div>
            )}
            {!price && (
              <div>
                <p>&nbsp;</p>
              </div>
            )}
          </TCCOutboundLink>
        </div>
        <div className="flex items-end flex-grow-0 mt-1 lg:mt-4">
          <div className="inline-block py-2">
            <TCCOutboundLink
              href={processedTargetUrl}
              title={targetUrlTitle}
              className="px-4 py-2 text-white border rounded-md shadow-md bg-green-landingPage800 border-green-landingPage800 hover:text-white hover:border-green-landingPage900 hover:bg-green-landingPage900"
            >
              Get Deal
            </TCCOutboundLink>
          </div>
        </div>
      </div>
    </div>
  );
};

FeaturedPickCard.propTypes = {
  props: PropTypes.shape({
    overviewTextComparisonUrl: PropTypes.string.isRequired,
    supplier: PropTypes.string.isRequired,
    productCategory: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    imageKey: PropTypes.string.isRequired,
    imageAltText: PropTypes.string.isRequired,
    overviewText: PropTypes.string.isRequired,
    serviceDetails: PropTypes.string.isRequired,
    targetUrl: PropTypes.string.isRequired,
    targetUrlTitle: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
  }),
};

export default FeaturedPickCard;
