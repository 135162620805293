import React from "react";
import PropTypes from "prop-types";
import DealCard from "../DealCard";
import TestimonialCard from "../TestimonialCard";
import BlogArticleCard from "../BlogArticleCard";
import FeaturedPickCard from "../FeaturedPickCard";
import InfoBoxCard from "../InfoBoxCard";
import InfoBoxAdCard from "../InfoBoxAdCard";
import { v4 as uuidv4 } from "uuid";
import { is } from "../../../../utils/BooleanUtils";

/**
 * Returns a container element that will render one or more Featured Cards based on the supplied configuration.
 *
 * @param{Object} props An object containing the configuration data describing the cards to be rendered within this instance of the container.
 *
 * @returns{Element} An element that will render the various Featured content based on the supplied configuration.
 */
const FeaturedCardsContainer = (props) => {
  const { cardConfigurations } = props;

  return (
    <section className="container mx-auto mt-6">
      <div key={uuidv4()} className="flex flex-wrap -mx-px overflow-hidden sm:-mx-px md:-mx-px lg:-mx-px xl:-mx-px">
        {cardConfigurations.configurations.map((config) => {
          const itemKey = uuidv4();
          if (is(config.isTestimonial)) {
            return (
              <div key={itemKey} className="w-1/2 px-px my-px overflow-hidden rounded-md sm:my-px sm:px-px sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-px lg:px-px lg:w-1/4 xl:my-px xl:px-px xl:w-1/4">
                <TestimonialCard config={config} />
              </div>
            );
          }

          if (is(config.isFeaturedPickDeal)) {
            return (
              <div key={itemKey} className="w-1/2 px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-px lg:px-px lg:w-3/4 xl:my-px xl:px-px xl:w-2/4">
                <FeaturedPickCard config={config} />
              </div>
            );
          }

          if (is(config.isBlogArticles)) {
            return (
              <div
                key={itemKey}
                className="w-full px-px my-px overflow-hidden rounded-md sm:my-px sm:px-px sm:w-full md:my-px md:px-px md:w-full lg:my-px lg:px-px lg:w-full xl:my-px xl:px-px xl:w-1/4"
              >
                <BlogArticleCard config={config} />
              </div>
            );
          }

          if (is(config.isInfoBox)) {
            return (
              <div key={itemKey} className="w-full px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-1/2 md:my-px md:px-px md:w-full lg:my-px lg:px-px lg:w-2/4 xl:my-px xl:px-px xl:w-2/4">
                <InfoBoxCard config={config} />
              </div>
            );
          }

          if (is(config.isInfoBoxAd)) {
            return (
              <div key={itemKey} className="w-full px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-full md:my-px md:px-px md:w-full lg:my-px lg:px-px lg:w-2/4 xl:my-px xl:px-px xl:w-2/4">
                <InfoBoxAdCard config={config} />
              </div>
            );
          }

          if (is(config.isTopDeal)) {
            return (
              <div key={itemKey} className="w-1/2 px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-px lg:px-px lg:w-1/4 xl:my-px xl:px-px xl:w-1/4">
                <DealCard config={config} />
              </div>
            );
          }

          return <p>Unknown Card Type</p>;
        })}
      </div>
    </section>
  );
};

FeaturedCardsContainer.propTypes = {
  props: PropTypes.shape({
    cardConfigurations: PropTypes.object.isRequired,
  }),
};

export default FeaturedCardsContainer;
