import React, { useContext } from "react";
import LocationSVG from "../../../../../svg/LandingPage/ZipLocation/redlocation.svg";
import IpGeoDataContext from "../../../../contexts/ipGeoDataContext";
import { isNullEmptyOrUndefined } from "../../../../utils/StringUtils";
import { v4 as uuidv4 } from "uuid";

/**
 * This component renders the ip geo location data for a user.
 *
 * For Non-US location's the component will render a div element that explains that the deals shown are for US customers.
 *
 * For US locations the component will render a div element showing the zip, city and state.
 *
 * @returns {element} A div which details the users IP based Geo location data.
 */
export default () => {
  const ipGeoData = useContext(IpGeoDataContext);
  const loadingElement = (
    <span className="inline-block">
      <LocationSVG className="inline-block mr-2 align-middle border-b border-white" />
    </span>
  );

  /**
   * A function to generate an array of span elements each of which renders a single character from the user zip code.
   *
   * @param {Object} userGeoData An object containing the user geo location data. Must provide a zip field.
   * @returns {Array} An array of span elements. If no zip data is available then a single span element is returned
   * providing a placeholder. Otherwise the array will contain a span element for each character in the zip address.
   */
  const buildIpLocationElementArrayUsing = (userGeoData) => {
    if (userGeoData.isLoading || isNullEmptyOrUndefined(userGeoData.postalCode)) return loadingElement;

    return userGeoData.postalCode.split("").map((char) => (
      <span key={uuidv4()} className="inline-block mr-1 border-b border-gray-landingPage800 text-gray-landingPage800">
        {char}
      </span>
    ));
  };

  if (ipGeoData.isLoading) {
    return loadingElement;
  }

  if (ipGeoData.isUSBasedIpAddress()) {
    return (
      <div className="text-gray-landingPage800">
        <LocationSVG className="inline-block mr-2 align-middle border-b border-green-landingPage300" />
        {buildIpLocationElementArrayUsing(ipGeoData)}
        <span className={`${ipGeoData.isLoading ? `hidden ` : `inline-block `} ml-1 `}>
          {ipGeoData.city}, {ipGeoData.regionName}
        </span>
      </div>
    );
  }

  return (
    <div className="text-gray-landingPage800">
      <span className="inline-block mr-1 -ml-2 border-b border-green-landingPage300">&nbsp;our</span>
      <LocationSVG className="inline-block mr-1 align-middle border-b border-green-landingPage300" />
      <span className="inline-block border-b border-green-landingPage300">US located customers</span>
    </div>
  );
};
