import React, { useState, useEffect, useContext } from "react";
import HeroCopy from "../Copy";
import BackgroundImage from "gatsby-background-image";
import RandomDealContainer from "../RandomDealContainer";
import WindowSizeContext from "../../../../contexts/windowSizeContext";
import LandingPageImageContext from "../../../../contexts/landingPageImageContext";
import useHasMounted from "../../../../hooks/useHasMounted";
import productCategoryConfigurationData from "../../../../data/productCategoryConfigurationData";
import useNavigationData from "../../../../hooks/useNavigationData";
import { getRandomDealOfferingUsing } from "../../../../services/LandingPageRandomDealService";

/**
 * Returns an element that renders the landing page hero container.
 */
const HeroContainer = () => {
  const navigationData = useNavigationData();
  const { productCategoryConfigurations } = productCategoryConfigurationData;
  const windowSize = useContext(WindowSizeContext);
  const landingPageImages = useContext(LandingPageImageContext);
  const hasMounted = useHasMounted();

  const [imageSource, setImageSource] = useState(landingPageImages.heroDesktop.childImageSharp.fluid);
  const [imageHeight, setImageHeight] = useState("400px");
  const [randomDealOfferings] = useState(getRandomDealOfferingUsing(navigationData, productCategoryConfigurations));

  useEffect(() => {
    if (windowSize.isLargeScreenView) {
      setImageSource(landingPageImages.heroDesktop.childImageSharp.fluid);
      setImageHeight("600px");
    } else if (windowSize.windowSize === "md") {
      setImageSource(landingPageImages.heroMobile.childImageSharp.fluid);
      setImageHeight("360px");
    } else {
      setImageSource(landingPageImages.heroMobile.childImageSharp.fluid);
      setImageHeight("450px");
    }
  }, [windowSize, landingPageImages.heroDesktop.childImageSharp.fluid, landingPageImages.heroMobile.childImageSharp.fluid]);

  if (!hasMounted) {
    return null;
  }

  return (
    <BackgroundImage
      Tag="section"
      className="container mx-auto"
      fluid={imageSource}
      style={{
        backgroundPosition: "center left",
        height: `${imageHeight}`,
      }}
      backgroundColor={`#0485c&`}
    >
      <div className="flex flex-col items-center w-full h-full">
        <div className="flex-grow-0 mt-4 lg:flex-grow"></div>
        <HeroCopy className="flex-grow-0" />
        <div className="flex-grow-0 lg:flex-grow"></div>
        <RandomDealContainer randomDealData={randomDealOfferings} className="flex-grow-0" />
      </div>
    </BackgroundImage>
  );
};

export default HeroContainer;
