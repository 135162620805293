import React from "react";

/**
 * Returns an element that renders the Business affiliate block's descriptive copy.
 */
export default () => {
  return (
    <section className="container py-4 mx-auto my-4 leading-tight text-center text-gray-landingPage800">
      <div className="py-4">
        <h2 className="text-4xl font-bold">The Comparison Company - Business</h2>
        <h3 className="text-2xl text-gray-landingPage700">The best deals for your business, no matter the size.</h3>
        <p className="px-4 text-sm lg:px-6 lg:text-xl">In addition to our AI searches and independent reviews, we use most of these tools in our daily operations.</p>
        <p className="px-4 text-sm lg:px-6 lg:text-xl">It’s tough out there at the moment, so we’re making sure that you don’t waste time or money.</p>
      </div>
    </section>
  );
};
