/**
 * Will take a link url to a supplier page and update it with data from the supplied params object.
 *
 * @param {String} link A String containing the link data from the grid configuration.
 * @param {Object} params An object with any number of arguments that can be passed in to be added to the supplied link.
 */
export const generateLinkUrlWithParams = (link, params) => {
  if (!params) {
    return link;
  }

  let generateLinkUrl = link;

  if (params.zipCode) {
    const ZIPCODE_PLACEHOLDER = "zipcode=XXXXX";
    const zipCodeParam = `zipcode=${params.zipCode}`;
    generateLinkUrl = link.replace(ZIPCODE_PLACEHOLDER, zipCodeParam);
  }

  return generateLinkUrl;
};
