import React, { useContext } from "react";
import HeroRandomDealButton from "../RandomDealButton";
import { v4 as uuidv4 } from "uuid";
import WindowSizeContext from "../../../../contexts/windowSizeContext";
import PropTypes from "prop-types";

/**
 * Returns an element that renders the random deal buttons based on the product category configuration.
 */
const RandomDealContainer = (props) => {
  const { randomDealData } = props;
  const windowSize = useContext(WindowSizeContext);

  return (
    <div className="container mx-auto font-body">
      <h2 className="w-full text-center text-white">Trending Comparisons</h2>
      <div className="flex flex-wrap items-center content-center justify-center -mx-px overflow-hidden sm:-mx-px md:-mx-px lg:-mx-px xl:-mx-px">
        <div className="flex flex-wrap items-center justify-center w-9/12 py-4 overflow-hidden md:w-full lg:w-full">
          {randomDealData.map((randomDealOffering, index) => {
            return (
              <HeroRandomDealButton className="border border-white shadow-lg " index={index} key={uuidv4()} targetUrl={randomDealOffering.targetUrl} product={randomDealOffering.product}>
                {windowSize.isLargeScreenView ? randomDealOffering.largeIcon : randomDealOffering.smallIcon}
              </HeroRandomDealButton>
            );
          })}
        </div>
      </div>
    </div>
  );
};

RandomDealContainer.propTypes = {
  props: PropTypes.shape({
    randomDealData: PropTypes.array.isRequired,
  }),
};

export default RandomDealContainer;
