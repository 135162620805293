import { graphql, useStaticQuery } from "gatsby";

/**
 * Hook used to get all of the landing page images in a single query and return them as an object.
 *
 * Note - Each configured image query is assigned a tag which is used to access the image in the returned object.
 *
 * @returns{Object} An object where each image can be accessed by the tag associated to the image in the query.
 */
function useLandingPageImages() {
  const landingPageImageData = useStaticQuery(
    graphql`
      query {
        heroDesktop: file(relativePath: { eq: "landingpage/landingpagehero.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroMobile: file(relativePath: { eq: "landingpage/landingpageheroplain.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        electricRates: file(relativePath: { eq: "landingpage/topdealsoftheday/electricrates.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        studentLoans: file(relativePath: { eq: "landingpage/topdealsoftheday/studentloans.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        rentersInsurance: file(relativePath: { eq: "landingpage/topdealsoftheday/rentersinsurance.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cellPhones: file(relativePath: { eq: "landingpage/topdealsoftheday/cellphones.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tvPackages: file(relativePath: { eq: "landingpage/topdealsoftheday/tvpackages.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        internetDeals: file(relativePath: { eq: "landingpage/topdealsoftheday/internetdeals.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        networkSolutions: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/networksolutions.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        transUnion: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/transunion.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pulsePower: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/pulsepower.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        clearCover: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/clearcover.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        bestow: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/bestow.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        tingMobile: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/ting.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        canva: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/canva.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        weebly: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/weebly.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        xfinity: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/xfinity.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        setMeUpGroup: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/setmeupgroup.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        xoomEnergy: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/xoomenergy.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        creditKarma: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/creditkarma.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        creditAssistanceNetwork: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/creditassistancenetwork.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        kinInsurance: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/kin.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        tally: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/tally.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        lastPass: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/lastpass.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        coinbase: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/coinbase.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        handyCom: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/handy.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        blinkist: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/blinkist.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        lifeInsuranceInfo: file(relativePath: { eq: "landingpage/featureddeals/infoboxes/familyinsurance.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        deepSentinel: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/deepsentinel.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        scoutAlarm: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/scoutalarm.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        mintMobile: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/mintmobile.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        carInfo: file(relativePath: { eq: "landingpage/featureddeals/infoboxes/carinsurancead.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hrBlock: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/hrblock.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        freshBooks: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/freshbooks.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        loanDepot: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/loandepot.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        upgrade: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/upgrade.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        splash: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/splash.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        boroCar: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/borocar.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        saveMoneyInfo: file(relativePath: { eq: "landingpage/featureddeals/infoboxes/savemoneypiggyad.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        havenLife: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/havenlife.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        healthIQ: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/healthiq.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        constantContact: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/constantcontact.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        semRush: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/semrush.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        domainCom: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/domain.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        rocketInc: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/rocket.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bankNovo: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/novo.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        coverWallet: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/coverwallet.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        corpNet: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/corpnet.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        dunBradstreet: file(relativePath: { eq: "landingpage/featureddeals/supplierimages/dunbradstreet.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        mallory: file(relativePath: { eq: "landingpage/testimonials/mallory.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        travis: file(relativePath: { eq: "landingpage/testimonials/travis.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        evelyn: file(relativePath: { eq: "landingpage/testimonials/evelyn.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return landingPageImageData;
}

export default useLandingPageImages;
