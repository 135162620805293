import React from "react";
import Layout from "../components/Layout";
import HeroContainer from "../components/LandingPage/Hero/Container";
import TopDealsContainer from "../components/LandingPage/TopDeals/Container";
import StartSavingBlock from "../components/LandingPage/StartSavingBlock";
import FeaturedDealsCopyBlock from "../components/LandingPage/FeaturedDealsCopyBlock";
import MissionBlock from "../components/LandingPage/MissionBlock";
import EmailCaptureBlock from "../components/LandingPage/EmailCaptureBlock";
import featuredDealConfigurationData from "../data/LandingPage/featuredDealsConfigurationData";
import ZipLocationContainer from "../components/LandingPage/ZipLocation/Container";
import FeaturedCardsContainer from "../components/LandingPage/FeaturedCards/Container";
import useLandingPageImages from "../hooks/useLandingPageImages";
import LandingPageContext from "../contexts/landingPageImageContext";
import BusinessFeaturedDealsCopyBlock from "../components/LandingPage/BusinessFeaturedDealsCopyBlock";
import BusinessStartSavingBlock from "../components/LandingPage/BusinessStartSavingBlock";

export default () => {
  const { featuredDealsConfigurations } = featuredDealConfigurationData;
  const landingPageImages = useLandingPageImages();

  const getConfigurationForContainer = (containerId) => {
    return featuredDealsConfigurations.find((config) => config.containerId === containerId);
  };

  return (
    <LandingPageContext.Provider value={landingPageImages}>
      <Layout>
        <HeroContainer />
        <ZipLocationContainer />
        {/* <TopDealsContainer /> */}
        <FeaturedDealsCopyBlock />
        <FeaturedCardsContainer cardConfigurations={getConfigurationForContainer("1")} />
        <StartSavingBlock />
        <FeaturedCardsContainer cardConfigurations={getConfigurationForContainer("2")} />
        <FeaturedCardsContainer cardConfigurations={getConfigurationForContainer("3")} />
        <EmailCaptureBlock />
        <FeaturedCardsContainer cardConfigurations={getConfigurationForContainer("4")} />
        <FeaturedCardsContainer cardConfigurations={getConfigurationForContainer("5")} />
        <FeaturedCardsContainer cardConfigurations={getConfigurationForContainer("6")} />
        <FeaturedCardsContainer cardConfigurations={getConfigurationForContainer("7")} />
        <FeaturedCardsContainer cardConfigurations={getConfigurationForContainer("8")} />
        <FeaturedCardsContainer cardConfigurations={getConfigurationForContainer("9")} />
        <FeaturedCardsContainer cardConfigurations={getConfigurationForContainer("10")} />
        <BusinessFeaturedDealsCopyBlock />
        <FeaturedCardsContainer cardConfigurations={getConfigurationForContainer("11")} />
        <FeaturedCardsContainer cardConfigurations={getConfigurationForContainer("12")} />
        <BusinessStartSavingBlock />
        <MissionBlock />
      </Layout>
    </LandingPageContext.Provider>
  );
};
