import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import WindowSizeContext from "../../../../contexts/windowSizeContext";

/**
 * Returns an element that renders a Random Deal button. These are used in the landing page hero and link to an existing product category result page.
 *
 * @param {Object} props An object containing the data required to be rendered by the element.
 */
const RandomDealButton = (props) => {
  const { index, product, targetUrl, children } = props;
  const windowSize = useContext(WindowSizeContext);

  /**
   * Returns a dynamic tailwind width and height value based on the current window dimensions.
   *
   * @param {Object} windowSize An object that contains, state based, data relating to the current window dimensions.
   */
  const getDimensionFor = (windowSize) => {
    return windowSize === "lg" || windowSize === "xlg" ? "w-32 h-32" : "w-20 h-20";
  };

  /**
   * Returns a dynamic Tailwind top margin value based on the current window dimensions and he index of the current button in the array.
   *
   * @param {Object} windowSize An object that contains, state based, data relating to the current window dimensions.
   * @param {Number} index A number indicating the position in the array of the currently being rendered button.
   */
  const getMarginTopUsing = (windowSize, index) => {
    if (windowSize === "md" || windowSize === "lg" || windowSize === "xlg") {
      return "mt-0";
    }

    return index > 2 ? "mt-2" : "mt-0";
  };

  return (
    <Link
      to={targetUrl}
      className={`${getDimensionFor(windowSize.windowSize)} ${getMarginTopUsing(windowSize.windowSize, index)}
       p-2 mx-1 bg-white border border-white rounded-md text-gray-landingPage800 shadow-md lg:hover:text-gray-landingPage800 lg:hover:-mt-6`}
    >
      <div className="p-0 lg:p-4 ">
        <div className="text-center">{children}</div>
        <div className="w-full h-full pt-2 text-xs text-center lg:pt-4 lg:text-base lg:text-small">{product.split(" ")[0]}</div>
        <div className="w-full h-full pb-2 text-xs text-center lg:text-base lg:text-small">{product.split(" ")[1]}</div>
      </div>
    </Link>
  );
};

RandomDealButton.propTypes = {
  props: PropTypes.shape({
    index: PropTypes.number.isRequired,
    product: PropTypes.string.isRequired,
    targetUrl: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  }),
};

export default RandomDealButton;
