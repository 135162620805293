import React from "react";
import UserLocation from "../UserLocation";

/**
 * Returns an element that renders the landing page zip location UI.
 */
export default () => {
  return (
    <section className="container px-8 mx-auto font-body">
      <div className="flex flex-wrap justify-center px-8 pb-2 overflow-hidden text-center">
        <div className="flex items-center px-4 py-2 mt-4 border rounded-lg shadow-md lg:shadow-md bg-green-landingPage300 border-green-landingPage500">
          <div className="w-full overflow-hidden text-gray-500">
            <span className="mr-2 text-gray-landingPage800">Featured deals for</span>
            <span className="inline-block">
              <UserLocation locationIconColor="red" />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
