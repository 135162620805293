import React from "react";
import { Link } from "gatsby";
import useBlogData from "../../../../hooks/useBlogData";
import { v4 as uuidv4 } from "uuid";
import * as blogService from "../../../../services/BlogService";

/**
 * Returns an element that renders eight random blog posts from existing content.
 */
const BlogArticleCard = () => {
  const blogPostData = useBlogData().blogPostData;
  const filteredPopularArticles = blogService.getRandomArticlesUsing(blogPostData, 8);

  return (
    <div className="h-full px-2 py-4 mx-auto lg:px-0">
      <div className="flex flex-col h-full px-2 rounded-lg shadow-lg bg-gray-landingPage100">
        <div className="flex flex-col flex-grow">
          <h2 className="px-4 py-4 text-xl font-bold text-blue-landingPage700 xl:text-2xl">Related Articles</h2>
          <ul className="px-4 pt-0 text-gray-landingPage700">
            {filteredPopularArticles.map((articleData) => (
              <li className="list-none" key={uuidv4()}>
                <div className="inline-block py-2">
                  <Link className="text-sm xl-text-base blog-article-card text-gray-landingPage800 hover:text-gray-landingPage900 " to={articleData.slug}>{`${articleData.blogTitle}`}</Link>
                </div>
              </li>
            ))}
          </ul>
          <Link to="/blog" className="px-4 pb-2 text-lg lg:pb-0 text-blue-landingPage800 hover:text-blue-landingPage900">
            See all articles
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogArticleCard;
