import React from "react";
import { Link } from "gatsby";
import { isNullEmptyOrUndefined } from "../../utils/StringUtils";
import TCCOutboundLink from "../../components/TCCOutboundLink";

/**
 * Generates an internal link and inserts it the supplied bio text
 *
 * @param {String} bioText The text block to update.
 * @param {String} bioComparisonLink The url for the internal page to map to.
 * @param {String} productCategory The associated Product Category which the link is generated for.
 * @param {String} targetUrl The url for the supplier page that should be opened in a new tab when the non TCC link text is clicked.
 */
export const generateLandingPageSupplierLinkBioUsing = (bioText, bioComparisonLink, productCategory, targetUrl) => {
  const tccOutboundLinkClassName =
    "text-xs no-underline active:no-underline hover:no-underline active:text-gray-landingPage800 hover:text-gray-landingPage800 text-gray-landingPage800 sm:text-sm md:text-sm lg:text-sm";

  if (isNullEmptyOrUndefined(bioComparisonLink) || isNullEmptyOrUndefined(productCategory)) {
    return (
      <TCCOutboundLink href={targetUrl} className={tccOutboundLinkClassName}>
        {bioText}
      </TCCOutboundLink>
    );
  }

  const LINK_MARKER = "%/PRODUCT_CATEGORY/%";

  if (bioText.includes(LINK_MARKER)) {
    const comparisonLink = (
      <Link
        style={{ color: "#0768a5" }}
        activeClassName="text-green-landingPage800"
        className="text-blue-landingPage800 active:text-blue-landingPage800 hover:text-blue-landingPage900 hover:underline"
        to={bioComparisonLink}
      >
        {productCategory} here
      </Link>
    );
    const comparisonText = bioText.replace(LINK_MARKER, "");
    return (
      <span>
        <TCCOutboundLink href={targetUrl} className={tccOutboundLinkClassName}>
          {comparisonText}
        </TCCOutboundLink>
        {comparisonLink}.
      </span>
    );
  }

  return bioText;
};
