import React from "react";
import { randomize, getRandomNumberOfElementsFrom } from "../../utils/ArrayUtils";
import { convertToLowerCase } from "../../utils/StringUtils";

import BusinessBankingSvg from "../../../svg/LandingPage/Hero/Business/businessbanking.svg";
import BusinessCreditCardsSvg from "../../../svg/LandingPage/Hero/Business/businesscreditcards.svg";
import BusinessCreditServicesSvg from "../../../svg/LandingPage/Hero/Business/businesscreditservices.svg";
import BusinessInsuranceSvg from "../../../svg/LandingPage/Hero/Business/businessinsurance.svg";
import BusinessLoansSvg from "../../../svg/LandingPage/Hero/Business/businessloans.svg";
import BusinessPasswordSvg from "../../../svg/LandingPage/Hero/Business/businesspassword.svg";
import BusinessPayrollSvg from "../../../svg/LandingPage/Hero/Business/businesspayroll.svg";
import BusinessEmailMarketingSvg from "../../../svg/LandingPage/Hero/Business/emailmarketiing.svg";
import BusinessHostingServicesSvg from "../../../svg/LandingPage/Hero/Business/hostingservices.svg";
import BusinessSchedulingServicesSvg from "../../../svg/LandingPage/Hero/Business/schedulingservices.svg";
import BusinessVoIPServicesSvg from "../../../svg/LandingPage/Hero/Business/voipservices.svg";
import BusinessWebDomainServicesSvg from "../../../svg/LandingPage/Hero/Business/webdomainservies.svg";
import BusinessWebSiteCreationToolsSvg from "../../../svg/LandingPage/Hero/Business/websitecreationtools.svg";

import CellPhonesSvg from "../../../svg/LandingPage/Hero/Cell/cellphones.svg";
import CellPlansSvg from "../../../svg/LandingPage/Hero/Cell/cellplans.svg";

import CreditMonitoringSvg from "../../../svg/LandingPage/Hero/Financial/creditmonitoring.svg";
import CryptoSvg from "../../../svg/LandingPage/Hero/Financial/cryptocurrency.svg";
import IDProtectionSvg from "../../../svg/LandingPage/Hero/Financial/identityprotection.svg";
import PersonalBankingSvg from "../../../svg/LandingPage/Hero/Financial/personalbanking.svg";
import PersonalBudgetingSvg from "../../../svg/LandingPage/Hero/Financial/personalbudgeting.svg";
import PersonalCreditCardsSvg from "../../../svg/LandingPage/Hero/Financial/personalcreditcards.svg";
import PersonalDebtServicesSvg from "../../../svg/LandingPage/Hero/Financial/personaldebtservices.svg";
import PersonalTaxServicesSvg from "../../../svg/LandingPage/Hero/Financial/personaltaxservices.svg";

import HomeCleaningSvg from "../../../svg/LandingPage/Hero/Home/homecleaning.svg";
import HomeInternetSvg from "../../../svg/LandingPage/Hero/Home/homeinternet.svg";
import HomeSecuritySvg from "../../../svg/LandingPage/Hero/Home/homesecurity.svg";
import HomeTVSvg from "../../../svg/LandingPage/Hero/Home/tv.svg";
import PasswordManagerSvg from "../../../svg/LandingPage/Hero/Home/passwordmanager.svg";

import CarInsuranceSvg from "../../../svg/LandingPage/Hero/Insurance/carinsurance.svg";
import HealthInsuranceSvg from "../../../svg/LandingPage/Hero/Insurance/healthinsurance.svg";
import HomeInsuranceSvg from "../../../svg/LandingPage/Hero/Insurance/homeinsurance.svg";
import LifeInsuranceSvg from "../../../svg/LandingPage/Hero/Insurance/lifeinsurance.svg";
import MedicalInsuranceSvg from "../../../svg/LandingPage/Hero/Insurance/medicareinsurance.svg";
import MotorcycleInsuranceSvg from "../../../svg/LandingPage/Hero/Insurance/motorcycleinsurance.svg";
import RentersInsuranceSvg from "../../../svg/LandingPage/Hero/Insurance/rentersinsurance.svg";

import CarLoansSvg from "../../../svg/LandingPage/Hero/Loans/carloans.svg";
import HomeLoansSvg from "../../../svg/LandingPage/Hero/Loans/homeloans.svg";
import PersonalLoansSvg from "../../../svg/LandingPage/Hero/Loans/personalloans.svg";
import RefinancingLoansSvg from "../../../svg/LandingPage/Hero/Loans/refinancingloans.svg";
import StudentLoansSvg from "../../../svg/LandingPage/Hero/Loans/studentrefinancing.svg";

import ElectricSvg from "../../../svg/LandingPage/Hero/Utilities/electricityrates.svg";
import GasSvg from "../../../svg/LandingPage/Hero/Utilities/gas.svg";

const largeIcons = [
  <BusinessBankingSvg className="inline-block w-8 h-8" imagekey="business banking" />,
  <BusinessCreditCardsSvg className="inline-block w-8 h-8" imagekey="business credit cards" />,
  <BusinessCreditServicesSvg className="inline-block w-8 h-8" imagekey="business credit services" />,
  <BusinessInsuranceSvg className="inline-block w-8 h-8" imagekey="business insurance" />,
  <BusinessLoansSvg className="inline-block w-8 h-8" imagekey="business loans" />,
  <BusinessPasswordSvg className="inline-block w-8 h-8" imagekey="business password management" />,
  <BusinessPayrollSvg className="inline-block w-8 h-8" imagekey="business payroll services & software" />,
  <BusinessEmailMarketingSvg className="inline-block w-8 h-8" imagekey="email marketing services" />,
  <BusinessHostingServicesSvg className="inline-block w-8 h-8" imagekey="web hosting services" />,
  <BusinessSchedulingServicesSvg className="inline-block w-8 h-8" imagekey="scheduling services" />,
  <BusinessVoIPServicesSvg className="inline-block w-8 h-8" imagekey="voip services" />,
  <BusinessWebDomainServicesSvg className="inline-block w-8 h-8" imagekey="web domain management" />,
  <BusinessWebSiteCreationToolsSvg className="inline-block w-8 h-8" imagekey="website creation tools" />,
  <CellPhonesSvg className="inline-block w-8 h-8" imagekey="cell phones" />,
  <CellPlansSvg className="inline-block w-8 h-8" imagekey="cell plans" />,
  <CreditMonitoringSvg className="inline-block w-8 h-8" imagekey="credit monitoring services" />,
  <CryptoSvg className="inline-block w-8 h-8" imagekey="cryptocurrency services" />,
  <IDProtectionSvg className="inline-block w-8 h-8" imagekey="identity protection services" />,
  <PersonalBankingSvg className="inline-block w-8 h-8" imagekey="personal bank accounts" />,
  <PersonalBudgetingSvg className="inline-block w-8 h-8" imagekey="personal budgeting tools" />,
  <PersonalCreditCardsSvg className="inline-block w-8 h-8" imagekey="personal credit cards" />,
  <PersonalDebtServicesSvg className="inline-block w-8 h-8" imagekey="personal debt services" />,
  <PersonalTaxServicesSvg className="inline-block w-8 h-8" imagekey="personal tax services" />,
  <HomeCleaningSvg className="inline-block w-8 h-8" imagekey="home cleaning services" />,
  <HomeInternetSvg className="inline-block w-8 h-8" imagekey="home internet services" />,
  <HomeSecuritySvg className="inline-block w-8 h-8" imagekey="home security services" />,
  <HomeTVSvg className="inline-block w-8 h-8" imagekey="home tv packages" />,
  <PasswordManagerSvg className="inline-block w-8 h-8" imagekey="password managers" />,
  <CarInsuranceSvg className="inline-block w-8 h-8" imagekey="car insurance" />,
  <HealthInsuranceSvg className="inline-block w-8 h-8" imagekey="health insurance" />,
  <HomeInsuranceSvg className="inline-block w-8 h-8" imagekey="home insurance" />,
  <LifeInsuranceSvg className="inline-block w-8 h-8" imagekey="life insurance" />,
  <MedicalInsuranceSvg className="inline-block w-8 h-8" imagekey="medicare insurance" />,
  <MotorcycleInsuranceSvg className="inline-block w-8 h-8" imagekey="motorcycle insurance" />,
  <RentersInsuranceSvg className="inline-block w-8 h-8" imagekey="renters insurance" />,
  <CarLoansSvg className="inline-block w-8 h-8" imagekey="car loans" />,
  <HomeLoansSvg className="inline-block w-8 h-8" imagekey="home loans" />,
  <PersonalLoansSvg className="inline-block w-8 h-8" imagekey="personal loans" />,
  <RefinancingLoansSvg className="inline-block w-8 h-8" imagekey="mortgage refinancing moans" />,
  <StudentLoansSvg className="inline-block w-8 h-8" imagekey="student loan refinancing" />,
  <ElectricSvg className="inline-block w-8 h-8" imagekey="electricity rates" />,
  <GasSvg className="inline-block w-8 h-8" imagekey="gas rates" />,
];

const smallIcons = [
  <BusinessBankingSvg className="inline-block w-6 h-6" imagekey="business banking" />,
  <BusinessCreditCardsSvg className="inline-block w-6 h-6" imagekey="business credit cards" />,
  <BusinessCreditServicesSvg className="inline-block w-6 h-6" imagekey="business credit services" />,
  <BusinessInsuranceSvg className="inline-block w-6 h-6" imagekey="business insurance" />,
  <BusinessLoansSvg className="inline-block w-6 h-6" imagekey="business loans" />,
  <BusinessPasswordSvg className="inline-block w-6 h-6" imagekey="business password management" />,
  <BusinessPayrollSvg className="inline-block w-6 h-6" imagekey="business payroll services & software" />,
  <BusinessEmailMarketingSvg className="inline-block w-6 h-6" imagekey="email marketing services" />,
  <BusinessHostingServicesSvg className="inline-block w-6 h-6" imagekey="web hosting services" />,
  <BusinessSchedulingServicesSvg className="inline-block w-6 h-6" imagekey="scheduling services" />,
  <BusinessVoIPServicesSvg className="inline-block w-6 h-6" imagekey="voip services" />,
  <BusinessWebDomainServicesSvg className="inline-block w-6 h-6" imagekey="web domain management" />,
  <BusinessWebSiteCreationToolsSvg className="inline-block w-6 h-6" imagekey="website creation tools" />,
  <CellPhonesSvg className="inline-block w-6 h-6" imagekey="cell phones" />,
  <CellPlansSvg className="inline-block w-6 h-6" imagekey="cell plans" />,
  <CreditMonitoringSvg className="inline-block w-6 h-6" imagekey="credit monitoring services" />,
  <CryptoSvg className="inline-block w-6 h-6" imagekey="cryptocurrency services" />,
  <IDProtectionSvg className="inline-block w-6 h-6" imagekey="identity protection services" />,
  <PersonalBankingSvg className="inline-block w-6 h-6" imagekey="personal bank accounts" />,
  <PersonalBudgetingSvg className="inline-block w-6 h-6" imagekey="personal budgeting tools" />,
  <PersonalCreditCardsSvg className="inline-block w-6 h-6" imagekey="personal credit cards" />,
  <PersonalDebtServicesSvg className="inline-block w-6 h-6" imagekey="personal debt services" />,
  <PersonalTaxServicesSvg className="inline-block w-6 h-6" imagekey="personal tax services" />,
  <HomeCleaningSvg className="inline-block w-6 h-6" imagekey="home cleaning services" />,
  <HomeInternetSvg className="inline-block w-6 h-6" imagekey="home internet services" />,
  <HomeSecuritySvg className="inline-block w-6 h-6" imagekey="home security services" />,
  <HomeTVSvg className="inline-block w-6 h-6" imagekey="home tv packages" />,
  <PasswordManagerSvg className="inline-block w-6 h-6" imagekey="password managers" />,
  <CarInsuranceSvg className="inline-block w-6 h-6" imagekey="car insurance" />,
  <HealthInsuranceSvg className="inline-block w-6 h-6" imagekey="health insurance" />,
  <HomeInsuranceSvg className="inline-block w-6 h-6" imagekey="home insurance" />,
  <LifeInsuranceSvg className="inline-block w-6 h-6" imagekey="life insurance" />,
  <MedicalInsuranceSvg className="inline-block w-6 h-6" imagekey="medicare insurance" />,
  <MotorcycleInsuranceSvg className="inline-block w-6 h-6" imagekey="motorcycle insurance" />,
  <RentersInsuranceSvg className="inline-block w-6 h-6" imagekey="renters insurance" />,
  <CarLoansSvg className="inline-block w-6 h-6" imagekey="car loans" />,
  <HomeLoansSvg className="inline-block w-6 h-6" imagekey="home loans" />,
  <PersonalLoansSvg className="inline-block w-6 h-6" imagekey="personal loans" />,
  <RefinancingLoansSvg className="inline-block w-6 h-6" imagekey="mortgage refinancing moans" />,
  <StudentLoansSvg className="inline-block w-6 h-6" imagekey="student loan refinancing" />,
  <ElectricSvg className="inline-block w-6 h-6" imagekey="electricity rates" />,
  <GasSvg className="inline-block w-6 h-6" imagekey="gas rates" />,
];

/**
 * Generates a random selection of six products offered by TCC and creates configuration data from the navigation and svg data to power the Random Deals
 * buttons in the Landing Page.
 *
 * Note - As a temporary feature, we are only returning product's that comprise a two word name.
 *
 * @param {Object} navigationData An object that contains the navigation (menu) dynamically generated data.
 * @param {Object} productCategoryConfigurations An object that contains the product category configuration data.
 */
export const getRandomDealOfferingUsing = (navigationData, productCategoryConfigurations) => {
  const nonPremiumDeals = navigationData.menuData.filter((menuItem) => {
    const productCategory = productCategoryConfigurations.find((pc) => convertToLowerCase(pc.category) === convertToLowerCase(menuItem.parentMenu));
    if (menuItem.menuText.split(" ").length <= 2) {
      return productCategory.premiumSubCatagories.indexOf(convertToLowerCase(menuItem.menuText)) < 0;
    }
    return null;
  });

  const randomizedDeals = randomize(nonPremiumDeals);
  const randomDealOfferings = getRandomNumberOfElementsFrom(randomizedDeals, 6);

  let result = [];
  randomDealOfferings.forEach(function (randomDeal) {
    const largeIcon = largeIcons.find((icon) => convertToLowerCase(icon.props.imagekey) === convertToLowerCase(randomDeal.menuText));
    const smallIcon = smallIcons.find((icon) => convertToLowerCase(icon.props.imagekey) === convertToLowerCase(randomDeal.menuText));
    result.push({
      product: randomDeal.menuText,
      largeIcon: largeIcon,
      smallIcon: smallIcon,
      targetUrl: randomDeal.slug,
    });
  });

  return result;
};
