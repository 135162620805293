import React from "react";

/**
 * Returns an element that renders the Landing page Hero Copy block.
 */
const HeroCopy = () => {
  return (
    <div className="container mx-auto font-body">
      <h1 className="w-full mt-4 text-5xl font-bold leading-none text-center text-yellow-landingPage700 lg:text-6xl md:mt-16">One Site.</h1>
      <h2 className="w-full text-2xl font-bold text-center text-white lg:text-5xl">Compare Everything.</h2>
    </div>
  );
};

export default HeroCopy;
