import React, { useState } from "react";
import { Input, Notification } from "antd";
import { MailOutlined } from "@ant-design/icons";
import axios from "axios";
import tccNotification from "../../../utils/tccNotification";
import { isNullEmptyOrUndefined } from "../../../utils/StringUtils";

/**
 * Returns an element that renders the landing page newsletter email address email capture form.
 *
 * The form data is posted to the "The Comparison Company Newsletter" form in getform.io.
 */
const EmailCaptureBlock = () => {
  const [serverState, setServerState] = useState({ submitting: false, status: null });
  const [emailAddress, setEmailAddress] = useState("");

  /**
   * Updates the emailAddress state value to the current value of the email address field.
   *
   * @param {String} evt A string containing the current value of the email address field.
   */
  const handleEmailAddressChange = (evt) => {
    setEmailAddress(evt.target.value);
  };

  /**
   * Generates and displays a TCC Notification using the provided arguments.
   *
   * @param {Object} notificationArguments An object containing the arguments to use to generate the TCC notification.
   */
  const displayNotificationUsing = (notificationArguments) => {
    Notification.open(notificationArguments);
  };

  /**
   * Generates the notification arguments that determine the TCC notification shown to the user based on the result of the form submission.
   *
   * Also cleans up state based on submission result.
   *
   * @param {Boolean} ok A Boolean that will be true if the form submitted as expected, without error.
   * @param {String} msg A String containing any message returned from the form submission attempt. Usually, an error message.
   * @param {Element} form An element representing the form that submitted the data.
   */
  const handleServerResponse = (ok, msg, form) => {
    const notificationArguments = ok
      ? tccNotification.buildSuccessNotificationArgsFrom("Thank You", "Your email address has been received & we will send our latest deals real soon.")
      : tccNotification.buildFailedNotificationArgsFrom(
          "There was an error",
          "Thank you for trying to submit your email address. It seems there was a problem that was likely our fault. Please try again."
        );

    displayNotificationUsing(notificationArguments);

    setServerState({ submitting: false, status: { ok, msg } });

    if (ok) {
      form.reset();
      setEmailAddress("");
    }
  };

  /**
   * Form submission handler. Attempts to submit the form data to GetForm.io. The response of which is handled by handleServerResponse.
   *
   * @param {Element} e An element representing the form that submitted the data.
   */
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/6d2391b6-912e-4027-aaca-3ffe6a450814",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Done", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <section className="container mx-auto mt-6 mb-4 rounded-md shadow-lg lg:px-0 text-gray-landingPage800 bg-blue-landingPage200">
      <div className="flex flex-wrap overflow-hidden">
        <div className="flex justify-center w-full py-10 overflow-hidden">
          <div className="w-full text-center lg:w-1/2">
            <h2 className="text-2xl font-bold lg:text-4xl">Be the first to grab new offers!</h2>
            <p className="text-xl leading-tight lg:text-3xl">Leave us your email and we'll send you the latest deals so you don't miss out on the best offers</p>
            <p className="px-4 text-sm lg:px-0 text-gray-landingPage800 lg:text-base">We value your privacy and won't share your information with anyone.</p>
            <form className="w-9/12 mx-auto lg:w-9/12" onSubmit={handleOnSubmit}>
              <label htmlFor="emailInput" className="text-blue-landingPage200" required="required">
                Email address
              </label>
              <Input
                type="email"
                value={emailAddress}
                onChange={handleEmailAddressChange}
                name="email"
                className="w-11/12 bg-white border rounded-md outline-none border-blue-landingPage300 lg:w-full"
                id="emailInput"
                aria-describedby="emailHelp"
                size="large"
                placeholder="Email address"
                prefix={<MailOutlined className="text-gray-100" />}
              />
              <button
                type="submit"
                className="w-11/12 h-10 py-1 mx-auto my-4 text-lg font-bold text-white border rounded-md shadow-lg disabled:cursor-not-allowed disabled:bg-green-landingPage700 disabled:border-green-landingPage700 disabled:text-white lg:w-full bg-green-landingPage800 border-green-landingPage800 hover:border-green-landingPage900 hover:bg-green-landingPage900 hover:text-white"
                disabled={isNullEmptyOrUndefined(emailAddress) || serverState.submitting}
              >
                Go
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmailCaptureBlock;
