/**
 * Generates an array of 5 elements which is used to depict the star ratings used within the site.
 *
 * Note - values less than 1 will result in a default of 1 and values greater than 5 will result in a default of 5.
 *
 * @param {Number} rating A number between 1 and 5 that is used to generate the resulting array.
 * @returns {Array} An array that contains 5 elements. The elements up to and including the rating value passed will be 1 and the remainder will be 0.
 */
export const createRatingArrayFrom = (rating) => {
  if (isNaN(rating)) {
    return Array.from(Array(5), (_, i) => 0);
  }

  let baseRating = rating;

  if (rating < 1) {
    baseRating = 1;
  }

  if (rating > 5) {
    baseRating = 5;
  }

  return Array.from(Array(5), (_, i) => (i <= baseRating - 1 ? 1 : 0));
};
