import React from "react";
import { Link } from "gatsby";

/**
 * Returns an element that renders the consumer affiliate Get Started link and associated copy.
 */
export default () => {
  return (
    <section className="container pt-4 mx-auto my-4">
      <div className="flex justify-center w-full px-4 py-2">
        <div className="w-full px-2 py-6 leading-tight border rounded-md shadow-lg bg-yellow-landingPage400 border-yellow-landingPage400 ">
          <div className="inline-block w-full mb-2 text-center lg:mb-0 lg:text-center lg:w-9/12">
            <p className="text-lg lg:text-xl xl:text-2xl">Create your own Savings Mode to get customized prices and get even better deals.</p>
          </div>
          <div className="inline-block w-full px-2 mt-2 text-center lg:mt-0 lg:text-left lg:w-3/12">
            <Link
              to="/app/login"
              title="Click to start saving with The Comparison Company"
              className="px-4 py-3 text-base text-white border rounded shadow-lg bg-green-landingPage800 border-green-landingPage800 lg:text-xl lg:px-6 lg:py-4 focus:text-white focus:bg-green-landingPage-800 focus:border-green-landingPage-800 hover:bg-green-landingPage900 hover:border-green-landingPage900 hover:text-white"
            >
              Start Saving
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
