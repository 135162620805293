import React from "react";
import { Link } from "gatsby";

/**
 * Returns an element that renders the Business affiliate block, Get Started component.
 */
export default () => {
  return (
    <section className="container pt-4 mx-auto mt-4">
      <div className="flex justify-center w-full px-4 py-2 mt-2 lg:mt-4">
        <div className="w-full px-2 py-6 border rounded-md shadow-lg bg-blue-landingPage800 border-blue-landingPage800 ">
          <div className="inline-block w-full mb-2 text-center lg:mb-0 lg:text-right lg:w-9/12">
            <p className="text-lg leading-tight text-white lg:text-base xl:text-2xl">We’re here to assist. You can see our range of business products at the top of the page.</p>
          </div>
          <div className="inline-block w-full mt-2 text-center lg:px-2 lg:mt-0 lg:text-left lg:w-3/12">
            <Link
              to="/app/login"
              title="Click to start saving with The Comparison Company"
              className="px-4 py-3 text-base border rounded shadow-lg text-gray-landingPage800 bg-yellow-landingPage800 border-yellow-landingPage800 lg:text-xl lg:px-6 lg:py-4 focus:text-gray-landingPage800 focus:bg-yellow-landingPage-800 focus:border-yellow-landingPage-800 hover:bg-yellow-landingPage900 hover:border-yellow-landingPage900 hover:text-gray-landingPage800"
            >
              Start Saving
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
