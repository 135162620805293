import React from "react";

/**
 * Context object used to store the Landing Page images.
 *
 * This context's value is an object containing all configured images from the useLandingPageImages hook.
 */
const LandingPageImageContext = React.createContext(null);

export default LandingPageImageContext;
