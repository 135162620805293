import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

/**
 * Returns an element that renders the TCC mission block for the Landing page.
 */
const MissionBlock = () => {
  const data = useStaticQuery(
    graphql`
      query {
        missionStatement: file(relativePath: { eq: "landingpage/missionstatement/missionstatement.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <section className="container pb-4 mx-auto mb-4">
      <div className="flex flex-wrap -mx-px overflow-hidden sm:-mx-px md:-mx-px lg:-mx-px xl:-mx-px">
        <div className="w-full px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-full md:my-px md:px-px md:w-full lg:my-px lg:px-px lg:w-5/12 xl:my-px xl:px-px xl:w-5/12">
          {/* Left */}
          <div className="mx-4 text-gray-landingPage800">
            <h2 className="px-2 pt-12 text-2xl font-bold leading-tight lg:px-1 lg:text-3xl lg:pl-0">The Comparison Company Mission</h2>
            <p className="w-11/12 text-sm xl:text-base lg:pt-4 xl:pt-10 ">The world is weird and difficult enough at the moment without worrying about utility bills.</p>
            <p className="w-11/12 text-sm xl:text-base ">
              Nobody enjoys shopping for utilities, so we built a way to simplify the process. The goal is to save you time and money while protecting your privacy. It’s as simple as that.
            </p>
            <p className="w-11/12 text-sm xl:text-base ">One Site, All Utilities.</p>
            <p className="w-11/12 text-sm xl:text-base ">Other Sites Just Don’t Compare.</p>
          </div>
        </div>
        <div className="w-full px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-full md:my-px md:px-px md:w-full lg:my-px lg:px-px lg:w-7/12 xl:my-px xl:px-px xl:w-7/12">
          {/* Right */}
          <div className="px-2 pt-12">
            <Img className="rounded-lg shadow-lg" fluid={data.missionStatement.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionBlock;
